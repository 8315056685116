import { createSlice } from '@reduxjs/toolkit';
import { getSearchedTreatments, getTreatmentById, getTreatments } from './treatmentsThunk';
const initialState = {
    allTreatments: [],
    oneData: undefined,
    allTreatmentsStatus: 'idle',
    error: null,
    total: 0,
    loading: false,
};
const slice = createSlice({
    name: 'materiaux',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTreatments.pending, (state, actions) => {
            state.allTreatmentsStatus = 'loading';
        })
            .addCase(getTreatments.fulfilled, (state, { payload }) => {
            state.allTreatmentsStatus = 'succeeded';
            state.allTreatments = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getTreatments.rejected, (state, { payload }) => {
            state.error = true;
            state.allTreatmentsStatus = 'failed';
        })
            .addCase(getSearchedTreatments.pending, (state, actions) => {
            state.allTreatmentsStatus = 'loading';
        })
            .addCase(getSearchedTreatments.fulfilled, (state, { payload }) => {
            state.allTreatmentsStatus = 'succeeded';
            state.allTreatments = payload.data.docs;
            state.total = payload.data.totalDocs;
        })
            .addCase(getSearchedTreatments.rejected, (state) => {
            state.error = true;
            state.allTreatmentsStatus = 'failed';
        })
            .addCase(getTreatmentById.pending, (state) => {
            state.error = false;
            state.loading = true;
        })
            .addCase(getTreatmentById.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.oneData = payload?.data?.data;
        })
            .addCase(getTreatmentById.rejected, (state) => {
            state.error = true;
            state.loading = false;
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const {} = slice.actions;
